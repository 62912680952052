<script lang="ts" setup>
import { useUserStore, useUserProfileStore } from "@/store/userStore";

const localePath = useLocalePath();
const storeUserProfile = useUserProfileStore();
const router = useRouter();
const { userProfile } = storeToRefs(storeUserProfile);
const store = useUserStore();
const { isLoggedIn, user } = storeToRefs(store);

const isLoginModalVisible = ref(false);
const isOtpModalVisible = ref(false);
const isProfileModalVisible = ref(false);
const phone = ref("");
const visible = ref(false);

const navItems = [
  { to: "/", title: "الصفحة الرئيسية" },
  { to: "/landing/evaluation", title: "سعّر السيارات المستخدمة" },
  { to: "/landing/inspection", title: "فحص السيارات" },
  { to: "/landing/warranty", title: "ضمان السيارات" },
  { to: "/", title: "البيانات والأفكار" },
  { to: "/", title: "الحلول التقنية" },
  { to: "/landing/mobile-app-1", title: "كارترست" },
];

async function handleLogout() {
  try {
    store.removeUserFromLocalStorage();
    storeUserProfile.removeUserProfileFromLocalStorage();
    router.push(localePath("/"));
  } catch (error) {
    console.log(error);
  }
}
</script>

<template>
  <BNavbar toggleable="lg" class="app-navbar">
    <BNavbarBrand :to="$localePath('/')">
      <img src="/assets/images/logo.png" alt="" />
    </BNavbarBrand>
    <BNavbarToggle target="nav-collapse" class="border-0" />
    <BCollapse id="nav-collapse" is-nav>
      <BNavbarNav class="me-auto gap-2">
        <!-- extra nav item to show the logo and closeicon for small screen -->
        <BNavItem class="d-block d-md-none navitem-top-sm-only">
          <img src="/assets/images/logo.png" alt="" />

          <BNavbarToggle target="nav-collapse" class="border-0">
            <template #default="{ expanded }">
              <img src="/assets/images/cross.svg" width="20px" height="auto" alt="" />
            </template>
          </BNavbarToggle>
        </BNavItem>
        <!-- extra nav item to show the logo and closeicon for small screen -->

        <BNavItem
          v-for="navItem in navItems"
          :to="$localePath(navItem.to)"
          :active="$route.path === $localePath(navItem.to)"
          >{{ navItem.title }}</BNavItem
        >
      </BNavbarNav>
      <!-- Right aligned nav items -->
      <BNavbarNav class="me-auto mb-2 mb-lg-0">
        <BNavItemDropdown
          v-if="isLoggedIn"
          size="lg"
          variant="link"
          toggle-class="text-decoration-none py-0"
          no-caret
        >
          <template #button-content>
            <div class="d-flex gap-2 align-items-center">
              <BAvatar
                v-if="userProfile?.user_image"
                :src="userProfile?.user_image"
                rounded="circle"
                variant="secondary"
                class="p-0"
                size="62px"
              />
              <BAvatar
                v-else
                rounded="circle"
                variant="secondary"
                size="44px"
                class="p-0"
              >
                <img src="/assets/images/avatar-default.jpg" class="img-fluid" alt="" />
              </BAvatar>

              <!-- <div class="avatar text-start">
            <div class="avatar__welcome">مرحبًا بعودتك</div>
            <div class="avatar__name">{{ user.first_name }} {{ user.last_name }}</div>
          </div> -->
            </div>
          </template>
          <BDropdownItem :to="$localePath('/dashboard')">Dashboard</BDropdownItem>
          <BDropdownItem @click.prevent="handleLogout">Logout</BDropdownItem>
        </BNavItemDropdown>
        <BNavItem v-else>
          <BButton
            variant="primary"
            @click.stop="isLoginModalVisible = true"
            class="fs-16 fw-600 px-3 h-auto py-3"
            size="lg"
          >
            دخول / تسجيل جديد
          </BButton>
        </BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>

  <AppLoginModal
    v-model:visible="isLoginModalVisible"
    v-model:phone="phone"
    @success="isOtpModalVisible = true"
  />
  <AppOtpModal
    v-model:visible="isOtpModalVisible"
    :phone="phone"
    @success="phone = ''"
    @openProfileModal="
      () => {
        phone = '';
        isProfileModalVisible = true;
      }
    "
  />
  <AppProfileModal v-model:visible="isProfileModalVisible" />
</template>

<style lang="css" scoped>
.app-navbar {
  background: #ffffff;
  border-bottom: 1px solid rgba(195, 212, 233, 0.4);
  height: 100%;
  max-height: 92px;
}

.app-navbar :deep(.nav-link) {
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-align: right;
  color: #1e1e1e;
}

.app-navbar :deep(.navitem-top-sm-only) a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
